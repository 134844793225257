<template>
  <div>
    <div>
      <v-menu offset-y max-height="300">
        <template v-slot:activator="{ attrs, on }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            dense
            label="Interesado en:"
            hint="Ciudad, desarrollos, zona, etc. (obligatorio * )"
            persistent-hint
            v-model="zoneSelected"
          >
          </v-text-field>
        </template>

        <v-list v-if="realEstateListFilter.length <= 0">
          <v-list-item link>
            <v-list-item-title>Sin coincidencias</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            v-for="item in realEstateListFilter"
            :key="item.name"
            link
          >
            <v-btn block text @click="selectedZones(item)">
              {{ item.name }}
              <v-spacer></v-spacer>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuperZones",

  props: {
    realEstateList: {
      Array: Array,
      default: false
    }
  },
  data: () => ({
    autoUpdate: true,
    isUpdating: false,
    zoneSelected: null
  }),

  computed: {
    realEstateListFilter() {
      if (this.zoneSelected) {
        let temp = this.realEstateList.filter(dt =>
          dt.name.toUpperCase().includes(this.zoneSelected.toUpperCase())
        );

        return temp;
      } else {
        return this.realEstateList;
      }
    }
  },

  watch: {
    zoneSelected(val) {
      this.$emit("setZones", val);
    }
  },

  methods: {
    selectedZones(item) {
      if (item) {
        this.zoneSelected = item.name;
      }
    }
  }
};
</script>
